import { from, map, mergeMap, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from 'src/libs/env/environment';
import { Page } from 'src/app/shared/pagination';
import { DocumentFile } from './model/document.model';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient, private environment: Environment) {}

  public download(id: string): Observable<DocumentFile> {
    return this.http.get<DocumentFile>(
      `${this.environment.api.urlS3}/${id}`

    );
  }

 /* public downloadBase64(url): Observable<DocumentFile> {
    return this.http.get<DocumentFile>(`${url}`);
  }*/

  public downloadBase64(url): Observable<any> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
        map(blob => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64data = reader.result;
                    // Remove o prefixo "data:*/*;base64," se existir
                    const base64Content = base64data.toString().split(',')[1] || base64data;
                    resolve({
                        fileBase64: base64Content,
                        contentType: blob.type
                    });
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        }),
        mergeMap(promise => from(promise))
    );
}

  public getUrlAdmin(id: string): string {
    return (
      `${this.environment.api.urlS3}/${id}`
    );
  }

  public getUrlSocio(id: string): string {
    return (
      `${this.environment.api.urlS3}/${id}`
    );
  }
}
