import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoDigitYear'
})
export class TwoDigitYearPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    const [month, year] = value.split('/');
    if(!year) return value;
    const twoDigitYear = year?.slice(-2);
    return `${month}/${twoDigitYear}`;
  }
}