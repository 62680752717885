import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// services
import { AuthenticationService } from '../service/auth.service';
import { MessageService } from 'src/app/shared/message';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private messageService: MessageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUser();
        const role = route.data['role'];

        if (currentUser) {
            if (role) {
                const authorized = currentUser.roles.includes(role);

                if (!authorized) {
                    this.messageService.addMsgDanger({ message: "Você não tem permissão para acessar essa página" });
                    
                    // Realiza o logout do usuário
                    this.authenticationService.logout();
                    
                    // Redireciona para a página de login
                    this.router.navigate(['auth/login'], { 
                        queryParams: { returnUrl: state.url } 
                    });
                    
                    return false;
                }
            }
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['auth/login'], { 
            queryParams: { returnUrl: state.url } 
        });
        return false;
    }
}