import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../service/auth.service';
import { NO_AUTH } from './no-auth.inteceptor';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Não adiciona token se estiver marcado como NO_AUTH ou se for uma requisição para o S3
        if (request.context.get(NO_AUTH) || request.url.includes(environment.api.urlS3)) {
            return next.handle(request);
        }

        // add authorization header with jwt token if available
        const currentUser = this.authenticationService.currentUser();
        if (currentUser && currentUser.accessToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.accessToken}`
                }
            });
        }

        return next.handle(request);
    }
}