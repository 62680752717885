import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormatYearTwo'
})
export class DateFormatYearTwoPipe implements PipeTransform {
  transform(date: string | Date | null | undefined): string {
    if (!date) {
      return '-----';
    }
    if (date instanceof Date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = (date.getFullYear() % 100).toString().padStart(2, '0');
      return `${day}/${month}/${year}`;
    } else {
      const dateSplit = date.split('T')[0].split('-');
      const day = dateSplit[2];
      const month = dateSplit[1];
      const year = (parseInt(dateSplit[0], 10) % 100).toString().padStart(2, '0');
      return `${day}/${month}/${year}`;
    }
  }
}