import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbreviatePaymentMethod'
})
export class AbbreviatePaymentMethodPipe implements PipeTransform {
  transform(paymentMethod: string): string {
    if (!paymentMethod) return '';
    //verificar se paymentMethod tem a palavra Dinheiro e se tiver, trocar por Din.
    if (paymentMethod.toLocaleLowerCase().includes('cartão')) {
      if(paymentMethod.toLocaleLowerCase().includes('crédito')) {
        return paymentMethod.replace('Cartão', 'C.').replace('Crédito', 'Créd.');
      }
      if(paymentMethod.toLocaleLowerCase().includes('débito')) {
        return paymentMethod.replace('Cartão', 'C.').replace('Débito', 'Déb.');
      }
    }

    if (paymentMethod.toLocaleLowerCase().includes('maquininha')) {
      return paymentMethod.replace('Maquininha', 'Maq.');
    }

    if (paymentMethod.toLocaleLowerCase().includes('recorrência')) {
      return paymentMethod.replace('Recorrência', 'Rec.');
    }


    return paymentMethod;
  }
}